angular.module("app.models.reviews", []).service("ReviewsModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            API = {
                createReview: function (reviewableId, reviewableType, field) {
                    return $http.post(API_URL + "reviews", {
                        reviewable_id: reviewableId,
                        reviewable_type: reviewableType,
                        field: field,
                    });
                },
            };

        function extract(result) {
            return result.data;
        }

        model.createReview = function (reviewableId, reviewableType, review) {
            var deferred = $q.defer();

            API.createReview(reviewableId, reviewableType, review).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
